document.addEventListener('DOMContentLoaded', () => {
  const elmNode = document.getElementById('elm-main')
  window.app = Elm.Main.embed(elmNode)

  // Get jquery
  var $ = require('jquery');


  var config = {
		apiKey: "AIzaSyB_6l9LNYCw8RFhMwRZ2x1WRQyoA9Lj5IA",
		authDomain: "torchlight-youth-mentoring.firebaseapp.com",
		databaseURL: "https://torchlight-youth-mentoring.firebaseio.com",
		projectId: "torchlight-youth-mentoring",
		storageBucket: "torchlight-youth-mentoring.appspot.com",
		messagingSenderId: "28912230197"
    }
    firebase.initializeApp(config);


    // get date and time as an integer to send to Firebase
    //console.log(new Date("2020-03-08T13:30:00.000").getTime())




    // Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;

    var browserType = (isOpera) ? "opera" : (isFirefox) ? "firefox" : (isSafari) ? "safari" : (isIE) ? "ie" : (isEdge) ? "edge" : (isChrome) ? "chrome" : (isBlink) ? "blink" : "not-found"
    window.app.ports.gotBrowserType.send(browserType)

  // Include scripts
  $.getScript('js/login.js')
  $.getScript('js/events.js')
  $.getScript('js/about.js')
  $.getScript('js/utils.js')
  $.getScript('js/IMSembed.js')




    window.app.ports.getViewBoxSize.subscribe(function () {
      $(window).on("resize", sendBackViewBoxSize)

      sendBackViewBoxSize()
    })

    function sendBackViewBoxSize() {
      let h = document.defaultView.innerHeight
      let w = document.defaultView.innerWidth
      let size =
        { height: h
        , width: w
        }

      window.app.ports.gotViewBoxSize.send(size)

    }



    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }



    window.app.ports.getTimesVisitedFromCookie.subscribe(function() {
        if (getCookie('timesVisited')) {
            let qData = getCookie('timesVisited')
            qData = JSON.parse(qData)

            window.app.ports.returnTimesVisitedCookie.send(qData)
        }
        else { window.app.ports.returnTimesVisitedCookie.send(0) }
    })

    window.app.ports.setTimesVisitedCookie.subscribe(function (data) {
        let expDate = new Date()
        let tzDiff = expDate.getTimezoneOffset() / 60
        expDate.setDate(expDate.getDate() + 365)

        let times = data

        document.cookie = 'timesVisited='+ JSON.stringify(times) +'; expires=' + expDate.toString() +'; path=/';
    })






    window.app.ports.waitForFacebook.subscribe(function() {
      waitForFacebookObjects()
    })

    function waitForFacebookObjects() {
      let ele1 = document.getElementById("fb-root")
      let ele2 = document.getElementById("fb-page")

      if (ele1 && ele2) {
          let id = 'facebook-jssdk'
          let ele3 = document.getElementById(id)

          if (ele3) {
            waitForWindowFB()
          }

          var js = document.createElement('script'); js.id = id;
          js.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v11.0&appId=1481280145225423&autoLogAppEvents=1';
          document.head.appendChild(js);

      }
      else {
          setTimeout(function() { waitForFacebookObjects() }, 250)
      }
    }

    function waitForWindowFB() {
      if (window.FB) {
        try {
          window.FB.XFBML.parse();
        }
        catch (e) {
          console.log(e)
          console.log("FB not initialized")
        }
      }
      else {
        setTimeout(function() { waitForWindowFB() }, 250)
      }
    }



});
